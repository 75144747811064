<template>
    <ion-header>
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <div
                        class="shrink"
                        :style="!ChiTietLenhDangThucHien.maLenh ? 'opacity: 0' : ''"
                    >
                        <DxButton
                            type="default"
                            icon="mdi mdi-chevron-left"
                            class="ml-1"
                            @click="QuayLaiChiTietLenhDangThucHien"
                            :disabled="!ChiTietLenhDangThucHien.maLenh"
                        />
                    </div>
                    <DxButton
                        type="default"
                        icon="mdi mdi-help-circle-outline"
                        class="ml-1"
                        style="opacity: 0"
                    />
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">DANH SÁCH LỆNH</div>
                </slot>
                <slot name="after">
                    <div class="shrink">
                        <DxButton
                            icon="mdi mdi-filter-variant"
                            type="default"
                            @click="
                                () => {
                                    forceRerender();
                                    dialogLoc = true;
                                }
                            "
                        />
                        <DxButton
                            v-if="
                                $Core.AuthApi.TokenParsed.preferred_username !=
                                '0888888888'
                            "
                            type="default"
                            icon="mdi mdi-help-circle-outline"
                            class="ml-1"
                            @click="MoTrangHuongDan"
                        />
                    </div>
                </slot>
            </div>
        </ion-toolbar>
    </ion-header>

    <ion-content>
        <div class="date-box" id="date-box">
            <div class="justify-space-between row align-center">
                <div
                    class="title-date"
                    @click="
                        ifDialogCalendar = true;
                        dialogCalendar = true;
                    "
                >
                    {{ getThu({ date: new Date(filter.NgayXuatBenKH) }) }}
                    {{ getDate({ date: new Date(filter.NgayXuatBenKH) }) }}
                    tháng
                    {{ getMonth({ date: new Date(filter.NgayXuatBenKH) }) }} năm
                    {{ getYear({ date: new Date(filter.NgayXuatBenKH) }) }}
                    <i class="mdi mdi-menu-down"></i>
                </div>

                <DxButton
                    v-if="
                        !compare_date(
                            this.$Helper.getStartDay(new Date()),
                            this.$Helper.getStartDay(this.filter.NgayXuatBenKH),
                        )
                    "
                    styling-mode="text"
                    text="Hôm nay"
                    type="default"
                    @click="LayNgayHomNay"
                />
            </div>
            <div class="dateList" ref="dateList" @scroll="renderNgayThang">
                <div
                    class="dateItem"
                    v-for="(item, index) in ArrNgayThang"
                    :key="index"
                    :id="item.id"
                    :class="{
                        'chu-nhat': checkDay(item) == 'ChuNhat',
                        today: checkToday(item) == true,
                        active: NgayDangChon == item.id,
                    }"
                    @click="chonNgay(item)"
                >
                    <!-- active -->
                    <!-- <div class="item-thu"></div> -->
                    <div class="item-ngay">
                        <!-- <span style="font-weight: 400; font-size: 12px">
                            {{ getDay(item) }}
                        </span> -->
                        {{ getDate(item)
                        }}<span style="font-size: 12px">/{{ getMonth(item) }}</span>
                    </div>
                    <!-- <div class="item-thang">{{ getMonth(item) }}</div> -->
                </div>
            </div>

            <div
                class="canh-bao-lai-xe font-14 color-warning font-italic row justify-center px-3 py-1 text-xs-center"
            >
                Phải nhận lệnh trước khi Bến xe ký lệnh!
            </div>
        </div>

        <div class="content-lenh">
            <!-- ==============================Danh sách lệnh chưa, đã và không hoàn thành thực hiện============================== -->
            <DxList
                :data-source="data.DanhSachLenh"
                height="calc(100% - 8px)"
                page-load-mode="scrollBottom"
                key-expr="id"
                :hoverStateEnabled="false"
                :focusStateEnabled="false"
                :activeStateEnabled="false"
                no-data-text="Không có dữ liệu!"
                class="list-lenh-style"
            >
                <template #item="{ data: item }">
                    <div>
                        <!-- Bổ sung div để sửa lỗi khi build bị vỡ giao diện -->
                        <FrameItemVue
                            :item="item"
                            @TuChoiLenh="TuChoiLenh"
                            @XemBanTheHienLenh="XemBanTheHienLenh"
                            @NhanLenh="NhanLenh"
                            @QuayLaiChiTietLenh="QuayLaiChiTietLenh"
                            @ClickXeDenBen="ClickXeDenBen"
                            :ChiTietLenhDangThucHien="ChiTietLenhDangThucHien"
                        />
                    </div>
                    <!-- :key="`${componentKey}-1`" -->
                </template>
            </DxList>
            <div
                v-if="tempError"
                style="
                    text-align: left;
                    padding: 8px 16px 7px;
                    color: rgba(0, 0, 0, 0.54);
                    font-size: 12px;
                "
            >
                Không có dữ liệu!
            </div>
        </div>

        <!-- ====================================Popup Dừng hành trình ========================================= -->
        <PopupVue
            height="55%"
            :dialog="dialogDungHanhTrinh"
            title="Xác nhận xe gặp sự cố"
            buttonTextRight="Xác nhận"
            buttonTextLeft="Hủy"
            @close="closeDungHanhTrinh"
            :key="`${componentKey}-4`"
        >
            <template #content>
                <DungHanhTrinhVue
                    ref="DungHanhTrinh"
                    :lenhDangThucHien="data.LenhDangThucHien"
                />
            </template>
        </PopupVue>
        <!-- ====================================Popup Lọc ========================================= -->
        <PopupVue
            height="auto"
            v-model:dialog="dialogLoc"
            title="Lọc dữ liệu"
            buttonTextRight="Xác nhận"
            buttonTextLeft="Hủy"
            @close="closeLoc"
            classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
            :key="`${componentKey}-3`"
        >
            <template #content>
                <PopupLocDuLieuVue
                    ref="LocDuLieu"
                    :filter="filter"
                    @thongBaoLocDL="thongBaoLocDL"
                />
            </template>
        </PopupVue>

        <!-- ====================================Bản thể hiện lệnh ========================================= -->
        <PopupBanTheHienLenhVue
            :dialog="dialogBanTheHienLenh"
            :guidLenh="LenhDangChon.guidLenh"
            :laLenhGiay="LenhDangChon.laLenhGiay"
            :danhSachLenhGiay="LenhDangChon.danhSachBanTheHien || []"
            @closes="
                () => {
                    dialogBanTheHienLenh = false;
                }
            "
        />
        <!-- ====================================Popup Thông báo ========================================= -->
        <PopupVue
            height="30%"
            v-if="ifDialogThongBao"
            v-model:dialog="dialogThongBao"
            title=""
            heightScrollView="100%"
            :useButtonLeft="false"
            @close="
                () => {
                    dialogThongBao = false;
                }
            "
        >
            <template #content>
                <ThongBaoVue
                    :message="ParamThongBao.message"
                    :state="ParamThongBao.state"
                    :title="ParamThongBao.title"
                />
            </template>
        </PopupVue>
        <PopupVue
            height="auto"
            v-if="ifDialogCalendar"
            v-model:dialog="dialogCalendar"
            title=""
            heightScrollView="100%"
            :useButtonLeft="true"
            @close="closeCalendar"
            buttonTextRight="Xác nhận"
            buttonTextLeft="Hủy"
            classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16 popup-padding-top-8"
        >
            <template #content>
                <div class="calendar-container">
                    <DxCalendar
                        v-model:value="ngayDangChonDate"
                        v-model:zoom-level="zoomLevel"
                        :min="minDateValue"
                        :max="maxDateValue"
                        :onValueChanged="ChangeDate"
                        width="400"
                    >
                    </DxCalendar>
                </div>
            </template>
        </PopupVue>
    </ion-content>
</template>
<script>
import DungHanhTrinhVue from "../../../../src/components/QuanLyLenh/DungHanhTrinh";
import ThongBaoVue from "../../../../src/components/_Common/ThongBao";
import LenhDienTu from "../../../../class/LenhDienTu";
import LaiXe from "../../../../class/LaiXe";
import { DxList, DxTabs, DxButton } from "devextreme-vue";
import { IonContent, IonButton, onIonViewWillEnter } from "@ionic/vue";
import FrameItemVue from "./FrameItem";
import PopupLocDuLieuVue from "./PopupLocDuLieu";
import PopupBanTheHienLenhVue from "./PopupBanTheHienLenh";
import { mapState } from "vuex";
import DxCalendar from "devextreme-vue/calendar";
import PopupVue from "../../../../src/components/_Common/Popup";
export default {
    components: {
        DungHanhTrinhVue,
        PopupVue,
        PopupBanTheHienLenhVue,
        ThongBaoVue,
        DxButton,
        DxTabs,
        DxList,
        IonContent,
        IonButton,
        PopupLocDuLieuVue,
        FrameItemVue,
        DxCalendar,
    },
    props: {
        isDanhSach: { type: Boolean, default: false },
        ChiTietLenhDangThucHien: { type: Object, default: {} },
        gioXuatBen: { default: new Date() },
    },
    data() {
        return {
            minDateValue: null,
            maxDateValue: null,
            zoomLevel: "month",
            dialogDungHanhTrinh: false,
            dialogBanTheHienLenh: false,
            ifDialogCalendar: false,
            dialogCalendar: false,
            ifDialogThongBao: false,
            dialogThongBao: false,
            dialogLoc: false,
            componentKey: 0,
            model: { Tab: 0 },
            tempError: false,
            LenhDangChon: {},
            data: {
                Tabs: [
                    {
                        id: 0,
                        text: "Chưa thực hiện",
                        soLuong: 0,
                    },
                    {
                        id: 1,
                        text: "Đang thực hiện",
                        soLuong: 0,
                    },
                    {
                        id: 2,
                        text: "Hoàn thành",
                        soLuong: 0,
                    },
                    {
                        id: 3,
                        text: "Không hoàn thành",
                        soLuong: 0,
                    },
                ],
                DanhSachLenh: [],
                LenhDangThucHien: new LenhDienTu(),
            },
            filter: {
                TrangThai: "0",
                NgayXuatBenKH: new Date(),
                TuyenVanChuyen: null,
                TimKiem: null,
                GioXuatBenKH: [],
            },
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            ArrNgayThang: [],
            NgayDangChon: "today",
            dateSelectCalendar: new Date(),
            itemLenhChuyen: {},
            DanhSachLenhCanHoanThanh: [],
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
        ngayDangChonDate() {
            return (
                new Date(
                    this.ArrNgayThang.find((item) => item.id === this.NgayDangChon).date,
                ) || new Date()
            );
        },
    },
    watch: {
        isDanhSach: {
            handler: function () {
                if (this.isDanhSach) {
                    if (this.ChiTietLenhDangThucHien.gioXuatBen) {
                        this.filter.NgayXuatBenKH = this.ChiTietLenhDangThucHien.gioXuatBen;
                    }
                    this.layDanhSachTatCaLenh();
                }
            },
            immediate: true,
        },
        dialogCalendar() {
            if (!this.dialogCalendar) {
                this.NgatDOMPopup("ifDialogCalendar");
            }
        },
        dialogThongBao() {
            if (!this.dialogThongBao) {
                this.NgatDOMPopup("ifDialogThongBao");
            }
        },
    },
    methods: {
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        dismiss() {
            this.$refs.modal.$el.dismiss();
        },
        forceRerender() {
            this.componentKey += 1;
        },
        async closeDungHanhTrinh(param) {
            if (!param || !this.$refs.DungHanhTrinh) {
                this.dialogDungHanhTrinh = false;
                return;
            }
            try {
                let valid = this.$refs.DungHanhTrinh.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    return;
                }
                let laiXe = new LaiXe();
                laiXe.maLenh = this.$refs.DungHanhTrinh.model.Malenh;
                laiXe.lyDo = this.$refs.DungHanhTrinh.model.LyDo;
                laiXe.danhSachHinhAnh = this.$refs.DungHanhTrinh.model.DanhSachHinhAnh;
                // Thực hiện dừng hành trình
                let rs = await laiXe.dungHanhTrinh(this.data.LenhDangThucHien.guidLenh);
                this.dialogDungHanhTrinh = false;
                if (!rs) {
                    throw new Error();
                }
                if (!rs.Data.status) {
                    return this.thongBao(
                        "Error",
                        "Xác nhận xe gặp sự cố thất bại! ",
                        rs.Data.message,
                    );
                }
                this.$router.push({
                    path: "/thanh-cong",
                    query: {
                        title: "Đã xác nhận xe gặp sự cố",
                        message: `Thông báo sẽ được gửi đến đơn vị ${this.data.LenhDangThucHien.tenDoanhNghiep}!`,
                        guidLenh: this.data.LenhDangThucHien.guidLenh,
                    },
                });
            } catch (error) {
                this.thongBaoLoi(error);
            }
        },
        closeLoc(param) {
            if (!param) {
                this.dialogLoc = false;
                return;
            }
            try {
                //Lấy thông tin lọc dữ liệu
                let valid = this.$refs.LocDuLieu.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    return;
                }
                let trangThai = this.$refs.LocDuLieu.model.TrangThai;

                let timKiem = this.$refs.LocDuLieu.model.TimKiem;
                let tuyen = this.$refs.LocDuLieu.model.TuyenVanChuyen;
                let ngay = this.$refs.LocDuLieu.model.NgayXuatBenKH;
                let gio = this.$refs.LocDuLieu.model.GioXuatBenKH;
                //Gán dữ liệu lọc
                this.filter.TrangThai = trangThai;
                this.filter.TimKiem = timKiem;
                this.filter.TuyenVanChuyen = tuyen;
                this.filter.NgayXuatBenKH = ngay;
                this.filter.GioXuatBenKH = gio;
                this.dialogLoc = false;
                //Không lọc theo đang thực hiện
                this.onItemClickTab(this.filter.TrangThai);
            } catch (error) {
                this.thongBaoLoi(error);
            }
        },

        onItemClickTab(e) {
            switch (e) {
                case this.$t("TrangThaiLenh_Loc.TatCa"):
                    this.layDanhSachTatCaLenh();
                    break;
                case this.$t("TrangThaiLenh_Loc.ChuaThucHien"):
                    this.layDanhSachLenhChuaThucHien();
                    break;
                case this.$t("TrangThaiLenh_Loc.DangThucHien"):
                    this.layDanhSachLenhDangThucHien();
                    break;
                case this.$t("TrangThaiLenh_Loc.HoanThanh"):
                    this.layDanhSachLenhDaHoanThanh();
                    break;
                case this.$t("TrangThaiLenh_Loc.KhongHoanThanh"):
                    this.layDanhSachLenhKhongHoanThanh();
                    break;

                default:
                    break;
            }
        },
        async layDanhSachTatCaLenh() {
            try {
                let query = {
                    timKiem: this.filter.TimKiem,
                    idLuongTuyen: this.filter.TuyenVanChuyen,
                    ngayXuatBenKeHoach: this.$Helper.getStartDay(
                        this.filter.NgayXuatBenKH,
                    ),
                    danhSachGioXuatBen: this.filter.GioXuatBenKH,
                };
                this.data.DanhSachLenh = (
                    await this.$store.dispatch("Lenh/layDSTatCaLenh", query)
                ).Store;
                this.generateNgayThang(new Date(this.filter.NgayXuatBenKH));
            } catch (ex) {
                this.thongBaoLoi("Lỗi hệ thống!");
            }
        },
        async layDanhSachLenhChuaThucHien() {
            try {
                let query = {
                    timKiem: this.filter.TimKiem,
                    idLuongTuyen: this.filter.TuyenVanChuyen,
                    ngayXuatBenKeHoach: this.$Helper.getStartDay(
                        this.filter.NgayXuatBenKH,
                    ),
                    danhSachGioXuatBen: this.filter.GioXuatBenKH,
                };
                this.data.DanhSachLenh = (
                    await this.$store.dispatch("Lenh/layDSLenhChuaThucHien", query)
                ).Store;
                this.generateNgayThang(new Date(this.filter.NgayXuatBenKH));
            } catch (ex) {
                this.thongBaoLoi("Lỗi hệ thống!");
            }
        },
        async layDanhSachLenhDangThucHien() {
            try {
                let query = {
                    timKiem: this.filter.TimKiem,
                    idLuongTuyen: this.filter.TuyenVanChuyen,
                    ngayXuatBenKeHoach: this.$Helper.getStartDay(
                        this.filter.NgayXuatBenKH,
                    ),
                    danhSachGioXuatBen: this.filter.GioXuatBenKH,
                };
                this.data.DanhSachLenh = (
                    await this.$store.dispatch("Lenh/layDSLenhDangThucHien", query)
                ).Store;
                this.generateNgayThang(new Date(this.filter.NgayXuatBenKH));
            } catch (ex) {
                this.thongBaoLoi("Lỗi hệ thống!");
            }
        },
        async layDanhSachLenhDaHoanThanh() {
            try {
                let query = {
                    timKiem: this.filter.TimKiem,
                    idLuongTuyen: this.filter.TuyenVanChuyen,
                    ngayXuatBenKeHoach: this.$Helper.getStartDay(
                        this.filter.NgayXuatBenKH,
                    ),
                    danhSachGioXuatBen: this.filter.GioXuatBenKH,
                };
                this.data.DanhSachLenh = (
                    await this.$store.dispatch("Lenh/layDSLenhDaHoanThanh", query)
                ).Store;
                this.generateNgayThang(new Date(this.filter.NgayXuatBenKH));
            } catch (ex) {
                this.thongBaoLoi("Lỗi hệ thống!");
            }
        },
        async layDanhSachLenhKhongHoanThanh() {
            try {
                let query = {
                    timKiem: this.filter.TimKiem,
                    idLuongTuyen: this.filter.TuyenVanChuyen,
                    ngayXuatBenKeHoach: this.$Helper.getStartDay(
                        this.filter.NgayXuatBenKH,
                    ),
                    danhSachGioXuatBen: this.filter.GioXuatBenKH,
                };
                this.data.DanhSachLenh = (
                    await this.$store.dispatch("Lenh/layDSLenhKhongHoanThanh", query)
                ).Store;
                this.generateNgayThang(new Date(this.filter.NgayXuatBenKH));
            } catch (ex) {
                this.thongBaoLoi("Lỗi hệ thống!");
            }
        },
        async layChiTietLenhDangThucHien() {
            try {
                let lenh = new LenhDienTu();
                let rs = await lenh.layChiTietLenhDangThucHien();
                if (!rs) {
                    throw new Error("Đã xảy ra lỗi. Vui lòng thử lại");
                }
                if (!rs.Data.status) {
                    return (this.tempError = true);
                }
                this.data.LenhDangThucHien = rs.Data.data;
            } catch (error) {
                console.error(error);
            }
        },
        thongBaoLocDL(error, message) {
            if (error == "LoiHeThong") {
                this.thongBaoLoi(message);
            } else if (error == "LoiMayChu") {
                this.thongBaoLoi("Lỗi kết nối đến máy chủ! " + message);
            } else if (error == "ClearFilter") {
                this.filter = {
                    TrangThai: "0",
                    NgayXuatBenKH: this.$refs.LocDuLieu.model.NgayXuatBenKH || new Date(),
                    TuyenVanChuyen: null,
                    TimKiem: null,
                    GioXuatBenKH: [],
                };
                this.dialogLoc = false;
                this.layDanhSachTatCaLenh();
            }
        },
        thongBaoLoi(message) {
            let toast = {
                message: message,
                type: "error",
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
        thongBaoThanhCong(message) {
            let toast = {
                message: message,
                type: "success",
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.ifDialogThongBao = true;
            this.dialogThongBao = true;
        },
        TuChoiLenh(item) {
            this.$emit("TuChoiLenh", item);
        },
        NhanLenh(item) {
            this.$emit("NhanLenh", item);
        },
        async getDataLenh() {
            this.ThongTinChuyenDi = {};
            this.LenhHienTai = {};
            try {
                this.$startLoading;

                let rs = await this.$Core.Api.LenhMobile(
                    this.$t("url.LayLenhHienTaiCuaLaiXe"),
                ).Get();
                this.$stopLoading;
                let lenhHienTaiCuaLaiXe = rs.Data.data;
                if (rs.Data.status === true) {
                    console.log(
                        "🚀 ~ file: DanhSachLenh.vue:866 ~ getDataLenh ~ lenhHienTaiCuaLaiXe",
                        lenhHienTaiCuaLaiXe,
                    );
                }
            } catch (error) {
                console.error(error);
                this.$stopLoading;
            }
        },
        XemBanTheHienLenh(item) {
            this.LenhDangChon = item;
            this.dialogBanTheHienLenh = true;
        },
        QuayLaiChiTietLenh(guidLenh, laLenhGiay) {
            this.$emit("QuayLaiChiTietLenh", guidLenh, laLenhGiay);
        },
        QuayLaiChiTietLenhDangThucHien() {
            this.$emit("QuayLaiChiTietLenhDangThucHien");
        },
        ClickXeDenBen(data) {
            this.$emit("ClickXeDenBen", data);
        },
        MoTrangHuongDan() {
            this.$router.push({
                path: "/huong-dan-su-dung/Xem-Chi-Tiet-Huong-Dan",
                query: {
                    name: this.$i18n.t("UrlHuongDan.QuanLyLenhChoLaiXe"),
                },
            });
        },
        layNgayQuaKhu(firstDate, soLuongNgayCanLay) {
            for (let index = 1; index < soLuongNgayCanLay + 1; index++) {
                let tmpfirstDate = firstDate;
                let item = {
                    id: this.$Helper.uuidv4(),
                    date: new Date(tmpfirstDate).setDate(firstDate.getDate() - index),
                };
                this.ArrNgayThang.unshift(item);
            }
        },
        layNgayTuongLai(firstDate, soLuongNgayCanLay) {
            for (let index = 1; index < soLuongNgayCanLay + 1; index++) {
                let tmpfirstDate = firstDate;
                let item = {
                    id: this.$Helper.uuidv4(),
                    date: new Date(tmpfirstDate).setDate(firstDate.getDate() + index),
                };
                this.ArrNgayThang.push(item);
            }
        },
        generateNgayThang(date) {
            let self = this;
            let firstDate = date || new Date();

            this.ArrNgayThang = [
                {
                    id: self.$Helper.uuidv4(),
                    date: firstDate,
                },
            ];
            this.NgayDangChon = this.ArrNgayThang[0].id;
            this.layNgayQuaKhu(firstDate, 15);
            this.layNgayTuongLai(firstDate, 15);

            setTimeout(() => {
                document.getElementById(this.NgayDangChon).scrollIntoView({
                    inline: "center",
                });
            }, 300);
        },
        getDay(item) {
            let day = new Date(item.date).getDay();
            return this.checkToday(item) ? "" : day == 0 ? "CN, " : `Th ${day + 1}, `;
        },
        getThu(item) {
            let day = new Date(item.date).getDay();
            return day == 0 ? "CN, " : `Thứ ${day + 1}, `;
        },
        getDate(item) {
            let date = new Date(item.date).getDate();
            return date;
        },
        getMonth(item) {
            let date = new Date(item.date);
            return `${date.getMonth() + 1}`;
        },
        getYear(item) {
            let date = new Date(item.date);
            return `${date.getFullYear()}`;
        },
        checkDay(item) {
            let day = new Date(item.date).getDay();
            return day == 0 ? "ChuNhat" : `NgayThuong`;
        },
        checkToday(item) {
            let date = new Date();
            let dateCheck = new Date(item.date);
            return (
                date.getDate() == dateCheck.getDate() &&
                date.getMonth() == dateCheck.getMonth() &&
                date.getFullYear() == dateCheck.getFullYear()
            );
        },
        checkMonth(item) {
            let date = new Date(item.date);
            return `${date.getMonth()}-${date.getFullYear()}`;
        },
        chonNgay(item) {
            this.NgayDangChon = item.id;
            this.filter.NgayXuatBenKH = new Date(item.date);
            this.layDsTatCaLenh();
        },

        compare_date(date1, date2) {
            if (date1.valueOf() < date2.getTime()) return false;
            else if (date1.valueOf() > date2.valueOf()) return false;
            else return true;
        },
        async layDsTatCaLenh() {
            try {
                let query = {
                    timKiem: this.filter.TimKiem,
                    idLuongTuyen: this.filter.TuyenVanChuyen,
                    ngayXuatBenKeHoach: this.$Helper.getStartDay(
                        this.filter.NgayXuatBenKH,
                    ),
                    danhSachGioXuatBen: this.filter.GioXuatBenKH,
                };
                this.data.DanhSachLenh = (
                    await this.$store.dispatch("Lenh/layDSTatCaLenh", query)
                ).Store;
            } catch (ex) {
                this.thongBaoLoi("Lỗi hệ thống!");
            }
        },
        renderNgayThang(e) {
            let scrollLeft = this.$refs.dateList.scrollLeft;
            let offsetWidth = this.$refs.dateList.offsetWidth;
            let scrollWidth = this.$refs.dateList.scrollWidth;
            let firstItem = new Date(this.ArrNgayThang[0].date);
            let firstItemId = this.ArrNgayThang[0].id;
            let lastItem = new Date(this.ArrNgayThang[this.ArrNgayThang.length - 1].date);
            if (scrollLeft == 0) {
                this.layNgayQuaKhu(firstItem, 7);
                setTimeout(() => {
                    document.getElementById(firstItemId).scrollIntoView({
                        inline: "start",
                    });
                }, 0);
            } else if (
                Math.floor(scrollLeft) == Math.floor(scrollWidth - offsetWidth) ||
                Math.floor(scrollLeft) == Math.floor(scrollWidth - offsetWidth - 1) ||
                Math.floor(scrollLeft) == Math.floor(scrollWidth - offsetWidth + 1)
            ) {
                this.layNgayTuongLai(lastItem, 7);
            }
        },
        ChangeDate(e) {
            this.dateSelectCalendar = e.value;
        },
        async closeCalendar(param) {
            if (!param) {
                this.dialogCalendar = false;
                return;
            }

            this.generateNgayThang(this.dateSelectCalendar);
            this.filter.NgayXuatBenKH = this.dateSelectCalendar;
            this.dialogCalendar = false;
            this.layDsTatCaLenh();
        },
        LayNgayHomNay() {
            this.generateNgayThang(new Date());
            this.filter.NgayXuatBenKH = new Date();
            this.layDanhSachTatCaLenh();
        },
    },
    created() {
        this.$emit("layChiTietLenhDangThucHienAction");
        if (this.gioXuatBen) {
            this.filter.NgayXuatBenKH = this.gioXuatBen;
            this.layDsTatCaLenh();
        }
    },
    mounted() {
        let self = this;

        onIonViewWillEnter(() => {});
    },
};
</script>
<style scoped>
>>> .tabpanel-style .dx-tabs-wrapper,
>>> .tabpanel-style .dx-item.dx-tab,
>>> .tabpanel-style .dx-item.dx-tab.dx-tab-selected {
    background-color: white !important;
}
>>> .tabpanel-style .dx-tab {
    padding: 8px 16px !important;
}
.tabpanel-style {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 99;
}
</style>
<style lang="scss" scoped>
.floating-button {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    box-shadow: $box_shadow;
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: white;
}

.date-box {
    position: fixed;
    top: 36px;
    left: 0;
    right: 0;
    height: auto;
    width: 100vw;
    background: #fff;
    z-index: 10;
    box-shadow: 0px 2px 2px #ddd;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.dateList {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    box-sizing: border-box;
    z-index: 2;
}

.dateItem {
    display: inline-block;
    width: auto;
    height: 100%;
    padding: 8px 12px;
    text-align: center;
    position: relative;
}
.dateItem .item {
    font-size: 16px;
    line-height: 1.2;
    color: #666;
}

.dateItem .item-ngay {
    display: block;
    font-size: 20px;
    color: #666;
    font-weight: 600;
}
.dateItem .item-thang,
.dateItem .item-thu {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #555;
    opacity: 0.7;
}
.dateItem.today .item-thu,
.dateItem.today .item-ngay,
.dateItem.today .item-thang {
    color: #03a9f4;
}
.dateItem.chu-nhat .item-thu,
.dateItem.chu-nhat .item-ngay,
.dateItem.chu-nhat .item-thang {
    color: red;
}
.dateItem.active .item-thu,
.dateItem.active .item-ngay,
.dateItem.active .item-thang {
    color: #fff;
}
.dateItem.active {
    background: #13b000cc;
    border-radius: 4px;
}

.dateItem.active .item-thang > span {
    color: #13b000;
}
.content-lenh {
    margin-top: 102px;
}

.calendar-container {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
// .btn-show-calendar {
//     position: absolute;
//     width: 100%;
//     height: 24px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: #fff;
//     /* border: 1px solid; */
//     top: 100%;
//     box-shadow: 0px 2px 2px #ddd;
//     border-bottom-left-radius: 10px;
//     border-bottom-right-radius: 10px;
//     z-index: 1;
// }
.btn-show-calendar > div {
    width: 42px;
    height: 4px;
    border-radius: 10px;
    background: #ccc;
}
::-webkit-scrollbar {
    display: none;
}
.title-date {
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-weight: bold;
    color: #666;
}
</style>
