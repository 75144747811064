<template>
    <div class="card-ticket">
        <div
            class="trang-thai"
            :style="` background-color: ${item.maMauTrangThai}; border-color: ${item.maMauTrangThai};`"
            v-if="item.idTrangThai == $t('TrangThaiLenh.KhongThucHienHanhTrinh')"
        >
            Không thực hiện
        </div>
        <div
            class="trang-thai"
            :style="` background-color: ${item.maMauTrangThai}; border-color: ${item.maMauTrangThai};`"
            v-else-if="item.idTrangThai == $t('TrangThaiLenh.KhongHoanThanhHanhTrinh')"
        >
            Không hoàn thành
        </div>
        <div
            class="trang-thai"
            :style="` background-color: ${item.maMauTrangThai}; border-color: ${item.maMauTrangThai};`"
            v-else-if="item.idTrangThai == $t('TrangThaiLenh.HoanThanh')"
        >
            Đã hoàn thành
        </div>
        <div
            class="trang-thai"
            :style="` background-color: ${item.maMauTrangThai}; border-color: ${item.maMauTrangThai};`"
            v-else-if="item.idTrangThai == $t('TrangThaiLenh.KhongDuDieuKienXuatBen')"
        >
            Không đủ điều kiện
        </div>
        <div
            class="trang-thai"
            :style="` background-color: ${item.maMauTrangThai}; border-color: ${item.maMauTrangThai};`"
            v-else
        >
            {{ item.tenTrangThai }}
        </div>
        <div class="row">
            <div class="xs12 pa-3 pb-2">
                <div :class="rowText">
                    <div
                        class="mr-2"
                        :class="item.laBenDi ? 'color-chieuDi' : 'color-chieuVe'"
                    >
                        <i class="mdi mdi-calendar-clock"></i>
                    </div>
                    <div :class="textRight">
                        {{
                            moment(item.thoiGianXuatBenKeHoach).format("HH:mm DD/MM/yyyy")
                        }}
                    </div>
                </div>
                <div :class="rowText" class="justify-space-between">
                    <div class="row align-center">
                        <div
                            class="mr-2"
                            :class="item.laBenDi ? 'color-chieuDi' : 'color-chieuVe'"
                        >
                            <i class="mdi mdi-card-bulleted-outline"></i>
                        </div>
                        <div :class="textRight">
                            <div class="row align-center">
                                {{ item.bienKiemSoat }}
                                (<span @click="XemBanTheHienLenh(item)">
                                    <div class="row align-center text-link">
                                        {{
                                            item.laLenhGiay && item.maLenh.length > 12
                                                ? item.maLenh.substr(0, 10) + "..."
                                                : item.maLenh
                                        }}
                                        <i
                                            class="mdi mdi-file-document"
                                            v-if="item.laLenhGiay"
                                        ></i>
                                    </div> </span
                                >)
                            </div>
                        </div>
                        <!-- <DxButton
                            styling-mode="text"
                            icon="mdi mdi-download"
                            @click="downloadImg(item)"
                        /> -->
                    </div>
                </div>
                <div class="row">
                    <div
                        :class="
                            item.idTrangThai == $t('TrangThaiLenh.ChoBenDiKy')
                                ? 'grow'
                                : 'xs12'
                        "
                    >
                        <div
                            :class="
                                rowText +
                                (item.laBenDi ? ' color-chieuDi' : ' color-chieuVe')
                            "
                        >
                            <div class="mr-2">
                                <i class="mdi mdi-bus-stop"></i>
                            </div>
                            <div :class="textRight">
                                {{ `${item.benDi}` }}
                            </div>
                        </div>
                        <div :class="rowText">
                            <div
                                class="mr-2"
                                :class="item.laBenDi ? 'color-chieuDi' : 'color-chieuVe'"
                            >
                                <i class="mdi mdi-map-marker-down"></i>
                            </div>
                            <div :class="textRight">
                                {{ `${item.benDen}` }}
                            </div>
                        </div>
                        <div :class="rowLastText">
                            <div
                                class="mr-2"
                                :class="item.laBenDi ? 'color-chieuDi' : 'color-chieuVe'"
                            >
                                <i class="mdi mdi-card-account-details-outline"></i>
                            </div>
                            <div :class="textRight">
                                {{ item.tenLaiXe }}
                            </div>
                        </div>
                        <!-- <div
                            :class="rowText"
                            :style="`color: ${item.maMauTrangThai}`"
                        >
                            <div class="mr-2">
                                <i class="mdi mdi-list-status"></i>
                            </div>
                            <div :class="textRight">
                                {{ item.tenTrangThai }}
                            </div>
                        </div> -->
                    </div>
                    <div
                        class="shrink mx-2"
                        style="width: 98px !important; height: 100px !important"
                        v-if="
                            item.idTrangThai == $t('TrangThaiLenh.ChoBenDiKy') ||
                            item.idTrangThai == $t('TrangThaiLenh.BenDiDaKy') ||
                            item.idTrangThai == $t('TrangThaiLenh.ChoXacNhanTraKhach')
                        "
                    >
                        <div class="row justify-center">
                            <img
                                @click="XemBanTheHienLenh(item)"
                                v-if="item && item.qrCode"
                                :src="QrCodeLink"
                                style="
                                    width: 98px !important;
                                    height: 100px !important;
                                    max-width: unset;
                                "
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="row"
            v-if="item.laLenhGiay"
            style="border-top: 1px solid #dadce0; padding: 0 2px"
        >
            <div class="xs12">
                <DxButton
                    :text="'Chi tiết Lệnh'"
                    type="warning"
                    styling-mode="text"
                    width="100%"
                    @click="QuayLaiChiTietLenh(item.guidLenh, true)"
                />
            </div>
        </div>
        <div class="row" v-else>
            <div class="xs12">
                <div
                    class="row"
                    style="border-top: 1px solid #dadce0; padding: 0 2px"
                    v-if="item.idTrangThai == $t('TrangThaiLenh.ChoKichHoat')"
                >
                    <div
                        class="xs6 text-xs-center"
                        style="border-right: 1px solid #dadce0"
                    >
                        <DxButton
                            text="Từ chối"
                            type="danger"
                            styling-mode="text"
                            width="100%"
                            @click="TuChoiLenh(item)"
                        />
                    </div>
                    <div class="xs6 text-xs-center">
                        <DxButton
                            text="Tiếp nhận"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            @click="NhanLenh(item)"
                        />
                    </div>
                </div>
                <div
                    class="row"
                    style="border-top: 1px solid #dadce0; padding: 0 2px"
                    v-if="item.idTrangThai != $t('TrangThaiLenh.ChoKichHoat')"
                >
                    <div
                        class="text-xs-center"
                        :class="
                            (item.idTrangThai == $t('TrangThaiLenh.HoanThanh') &&
                                item.laiXeDaHoanThanhHanhTrinh) ||
                            (item.idTrangThai == $t('TrangThaiLenh.BenDiDaKy') &&
                                item.laiXeDaHoanThanhHanhTrinh) ||
                            item.idTrangThai ==
                                $t('TrangThaiLenh.KhongThucHienHanhTrinh') ||
                            item.idTrangThai ==
                                $t('TrangThaiLenh.KhongHoanThanhHanhTrinh')
                                ? 'xs12'
                                : 'xs6'
                        "
                        style="border-right: 1px solid #dadce0"
                    >
                        <DxButton
                            :text="
                                item.idTrangThai == $t('TrangThaiLenh.HoanThanh') &&
                                item.laiXeDaHoanThanhHanhTrinh
                                    ? 'Xem lịch sử chuyến xe'
                                    : 'Chi tiết Lệnh'
                            "
                            type="warning"
                            styling-mode="text"
                            width="100%"
                            @click="QuayLaiChiTietLenh(item.guidLenh)"
                        />
                    </div>
                    <div
                        class="xs6 text-xs-center"
                        v-if="
                            ChiTietLenhDangThucHien.guidLenh == item.guidLenh &&
                            !item.laiXeDaHoanThanhHanhTrinh
                        "
                    >
                        <DxButton
                            :disabled="item.idTrangThai == $t('TrangThaiLenh.ChoBenDiKy')"
                            text="Đến bến"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            @click="ClickXeDenBen"
                        />
                    </div>
                    <div
                        class="xs6 text-xs-center"
                        v-else-if="
                            item.idTrangThai !=
                                $t('TrangThaiLenh.KhongThucHienHanhTrinh') &&
                            item.idTrangThai !=
                                $t('TrangThaiLenh.KhongHoanThanhHanhTrinh') &&
                            !item.laiXeDaHoanThanhHanhTrinh
                        "
                    >
                        <DxButton
                            text="Tiếp nhận"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            :disabled="
                                item.idTrangThai != $t('TrangThaiLenh.ChoBenDiKy') &&
                                item.idTrangThai !=
                                    $t('TrangThaiLenh.DangThucHienHanhTrinh') &&
                                item.idTrangThai !=
                                    $t('TrangThaiLenh.ChoXacNhanTraKhach') &&
                                item.idTrangThai != $t('TrangThaiLenh.BenDiDaKy') &&
                                item.idTrangThai != $t('TrangThaiLenh.HoanThanh')
                            "
                            @click="NhanLenh(item)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
import LenhDienTu from "../../../../class/LenhDienTu";
export default {
    components: {
        DxButton,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        ChiTietLenhDangThucHien: { type: Object, default: {} },
    },
    data() {
        return {
            rowText: "row align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
        };
    },
    computed: {
        QrCodeLink() {
            if (!this.item || !this.item?.qrCode) return "#";
            // let fcolor = this.renderColorQr(this.item?.laBenDi);
            let qrdata = this.item?.qrCode;
            return (
                `https://image-charts.com/chart?` +
                [
                    "cht=qr",
                    "icqrb=FFFFFF00",
                    // "icqrf=" + fcolor,
                    "chs=88x88",
                    "choe=UTF-8",
                    "chld=L",
                    "chl=" + qrdata,
                ].join("&")
            );
        },
    },
    methods: {
        TuChoiLenh(item) {
            this.$emit("TuChoiLenh", item);
        },
        NhanLenh(item) {
            this.$emit("NhanLenh", item);
        },
        XemBanTheHienLenh(item) {
            this.$emit("XemBanTheHienLenh", item);
        },
        async downloadImg(item) {
            let lenh = new LenhDienTu();
            lenh.guidLenh = item.guidLenh;
            let link = await lenh.layLinkXemBanTheHienLenh();
            let domain = "https://slaixe.sbus.vn/tai-lenh/";
            return (
                SonPhat.hasOwnProperty("openExternal") &&
                SonPhat.openExternal(domain + `?link=${link}`)
            );
        },
        QuayLaiChiTietLenh(guidLenh, laLenhGiay) {
            this.$emit("QuayLaiChiTietLenh", guidLenh, laLenhGiay);
        },
        ClickXeDenBen() {
            this.$emit("ClickXeDenBen", this.item);
        },
    },
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    word-break: break-word;
    white-space: break-spaces;
}

.color-chieuDi {
    color: #03a9f4;
}
.color-chieuVe {
    color: #13b000;
}
.trang-thai {
    position: absolute;
    top: 36px;
    right: -64px;
    transform: rotate(45deg);
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 4px 0;
    width: 224px;
    text-align: center;
    z-index: 1;
    opacity: 0.8;
    color: #fff;
}
</style>
