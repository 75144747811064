<template>
    <div>
        <DxValidationGroup ref="formValidation">
            <span class="font-12">Mã số lệnh</span>
            <DxTextBox
                class="mb-3"
                v-model="model.MaLenh"
                validationMessageMode="always"
                mode="text"
                :read-only="true"
                styling-mode="underlined"
            ></DxTextBox>
            <!-- mask="0000/00.00000" -->
            <span class="font-12">Dừng vì lý do (*)</span>
            <DxTextBox
                class="mb-4"
                v-model="model.LyDo"
                validationMessageMode="always"
                mode="text"
                styling-mode="underlined"
                ><DxValidator>
                    <DxRequiredRule
                        message="Dừng vì lý do không được bỏ trống!"
                    />
                    <DxStringLengthRule
                        :max="128"
                        message="Dừng vì lý do không được vượt quá 128 ký tự!"
                    />
                </DxValidator>
            </DxTextBox>
            <div class="row subtitle align-end">
                <div class="font-bold pr-2">Hình ảnh sự cố</div>
                <div class="font-12">{{ data.DanhSachHinhAnh.length }}/6</div>
            </div>
            <div class="row">
                <div v-for="(item, index) in data.DanhSachHinhAnh" :key="index">
                    <div
                        class="
                            anh-dai-dien-style
                            row
                            justify-center
                            align-center
                            ml-2
                        "
                        @click="getAnh"
                    >
                        <img :src="item" alt="" width="100%" height="auto" v-if="item" />
                        <i
                            v-else
                            class="mdi mdi-camera-plus-outline"
                            style="opacity: 0.5"
                        ></i>
                    </div>
                </div>
                <div
                    class="
                        anh-dai-dien-style
                        row
                        justify-center
                        align-center
                        ml-2
                    "
                    @click="getAnh"
                >
                    <i
                        class="mdi mdi-camera-plus-outline"
                        style="opacity: 0.5"
                    ></i>
                </div>
            </div>
        </DxValidationGroup>
    </div>
</template>
<script>
import { DxTextBox, DxValidationGroup, DxValidator } from "devextreme-vue";
import { DxRequiredRule, DxStringLengthRule } from "devextreme-vue/data-grid";
export default {
    components: {
        DxValidationGroup,
        DxTextBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
    },
    props: {
        lenhDangThucHien: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            url: "",
            model: {
                MaLenh: "",
                LyDo: "",
                DanhSachHinhAnh: [],
            },
            data: {
                DanhSachHinhAnh: [],
            },
        };
    },
    methods: {
        async getAnh() {
            if (this.model.DanhSachHinhAnh.length == 6) {
                return;
            }
            try {
                this.emitter.emit("loadding", false);
                let url = await cam.getPhoto();
                if (url) {
                    this.data.DanhSachHinhAnh.push(url);
                    let file = await cam.getFileData();
                    this.model.DanhSachHinhAnh.push(file);
                }
            } catch (error) {
                this.model.DanhSachHinhAnh = [];
                console.log(
                    "🚀 ~ file: DungHanhTrinh.vue ~ line 83 ~ getAnh ~ error",
                    error
                );
            }
        },
    },
    mounted() {
        this.model.DanhSachHinhAnh = [];
        this.data.DanhSachHinhAnh = [];
        if (this.lenhDangThucHien) {
            this.model.MaLenh = this.lenhDangThucHien.maLenh;
            this.data.DanhSachHinhAnh = this.lenhDangThucHien
                .danhSachHinhAnhSuCo
                ? this.lenhDangThucHien.danhSachHinhAnhSuCo
                : [];
        }
    },
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
.anh-dai-dien-style {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid $border;
    overflow: hidden;
}
</style>